<template>
    <div class="login">
        <el-input 
            placeholder="Login" 
            v-model="login"
        />
        <el-input 
            class="mt-1" 
            placeholder="Hasło" 
            type="password" 
            v-model="password" 
        />
        <el-button 
            class="mt-1" 
            type="primary"
            @click="loginIn"
            :loading="loading"
        >
            Log In
        </el-button>
    </div>
</template>
<script>


export default {
    data(){
        return {
            login: "",
            password: "",
            loading: false,
        }
    },
    methods: {
        async loginIn(){
            if(this.loading) {
                return;
            }
            if(this.login == "" || this.password == ""){
                this.$message.error('Podaj wszystkie dane');
                return;
            }
            this.loading = true;
            try{
                const r = await this.$axios.$post('/auth/login', {
                    login: this.login,
                    password: this.password
                });
                if(r && r.success == true) {
                    this.$store.commit('auth/setUser', {username: r.data.username});
                    this.$store.commit('auth/setAuthData', {token: r.data.token, expDate: r.data.token_exp_date, userRole: r.data.role});
                    this.$router.push("/");
                }else{
                    this.$message.error(r.msg);
                }
            } catch(e) {
                console.error(e)
                this.$message.error(e);
                this.loading = false;
            }
            
        }
    }
}
</script>
