export function formatDateTime(date) {
    let new_date = new Date(date);

    let day = new_date.getDate().toString();
    let month = (new_date.getMonth() + 1).toString();
    let fullYear = new_date.getFullYear();

    if(day.length == 1) day = '0' + day;
    if(month.length == 1) month = '0' + month;

    let h = new_date.getHours().toString();
    let m = new_date.getMinutes().toString();

    if(m.length == 1) m = '0' + m;

    return `${day}/${month}/${fullYear} ${h}:${m}`
}