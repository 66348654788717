<template>
<div class="home">
    <h2>Co rejestrujemy?</h2>
    <div class="mb-2">
        <el-checkbox v-model="register_image_checkbox">Zarejestruj obraz</el-checkbox>
        <el-checkbox v-model="register_text_checkbox">Zarejestruj tekst</el-checkbox>
    </div>
    <el-row :gutter="15">
        <el-col :sm="24" :md="12">
            <template v-if="register_text_checkbox">
            <el-input placeholder="Nazwa do zarejestrowania" v-model="register_text"></el-input>
            </template>
        </el-col>
        <el-col :sm="24" :md="12">
            <template v-if="register_image_checkbox">
            <img 
            style="max-width: 200px"
            :src="register_image" 
            v-if="register_image.length > 0">
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    :on-success="refresh"
                    drag
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :http-request="uploadFile"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="(f, f_l) => file_list_size = f_l.length"
                    :on-remove="(f, f_l) => file_list_size = f_l.length"
                    >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Przeciągnij pliki lub <em>kliknij aby przesłać</em></div>
                    <div class="el-upload__tip" slot="tip">jpg/png mniejsze niż 10mb</div>
                </el-upload>
            </template>
        </el-col>
    </el-row>
    <el-divider></el-divider>
    <h2>Regiony</h2>
    <div class="mb-2">
        <el-checkbox-group v-model="regions">
            <el-checkbox label="GB"></el-checkbox>
            <el-checkbox label="EU"></el-checkbox>
        </el-checkbox-group>
    </div>
    <el-divider></el-divider>
    <h2>Dane klienta</h2>
    <el-row :gutter="15" >
        <el-col :xs="24" :sm="12">
            <el-input class="mt-1" placeholder="Imie i nazwisko" v-model="client_data.name"></el-input>
            <el-input class="mt-1" placeholder="Kraj" v-model="client_data.country"></el-input>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-input class="mt-1" placeholder="Ulica i nr" v-model="client_data.street"></el-input>
            <el-input class="mt-1" placeholder="NIP" v-model="client_data.tax_number"></el-input>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-input class="mt-1" placeholder="Miasto" v-model="client_data.city"></el-input>
            <el-input class="mt-1" placeholder="Kod pocztowy" v-model="client_data.zip_code"></el-input>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-input class="mt-1" placeholder="Email" v-model="client_data.email"></el-input>
            <el-input class="mt-1" placeholder="Numer telefonu" v-model="client_data.phone"></el-input>
        </el-col>
    </el-row>
    <el-divider></el-divider>
    <h2>Klasy</h2>
    <div>
    <div class="mb-1">
        <el-card class="box-card mb-1" v-for="c in classes" :key="c._id" >
            <el-row :gutter="15" >
                <el-col :sm="24" :md="8">
                    <h4 class="mb-1">Numer</h4>
                    <el-input-number  
                        v-model="c.number" 
                        @change="v => handleChangeClass(v, c._id)" 
                        :min="1" 
                        :max="46"/>
                </el-col>
                <el-col :sm="24" :md="13">
                    <h4 class="mb-1">Podklasy</h4>
                    <el-input 
                        placeholder="Wpisz nazwę subklasy a następnie zatwierdź enterem" v-model="new_subclass_input"
                        v-on:keydown.enter.native="addSubclass(c._id)"
                        v-if="new_subclass_edited_id == c._id"
                    />
                    <ul>
                        <li v-for="sc in c.subclasses" :key="sc">
                            <span class="mr-1-2">
                                {{sc}}
                            </span>
                            <el-button 
                                size="mini" 
                                type="danger" 
                                icon="el-icon-delete" 
                                circle
                                plain
                                @click="deleteSubclass(sc, c._id)"
                            ></el-button>
                        </li>
                    </ul>
                </el-col>
                <el-col :sm="24" :md="3" style="display:flex;flex-direction:column; justify-content:flex-end;align-items:flex-end;">
                    <el-popconfirm
                    title="Czy napewno chcesz usunąć klasę?"
                    confirm-button-text ="Tak"
                    cancel-button-text ="Anuluj"
                    confirm-button-type="danger"
                    icon="el-icon-info"
                    icon-color="red"
                    @confirm="deleteClass(c._id)"
                    >
                         <el-button 
                            slot="reference"
                            type="danger" 
                            icon="el-icon-delete" 
                            size="small"
                            circle
                        />
                    </el-popconfirm>
                    <el-button
                        type="primary" 
                        icon="el-icon-edit" 
                        class="mt-1-2"
                        size="small"
                        circle
                        @click="new_subclass_edited_id = c._id"
                        v-if="new_subclass_edited_id != c._id"
                    />
                    <el-button
                        type="primary" 
                        icon="el-icon-close" 
                        class="mt-1-2"
                        size="small"
                        circle
                        @click="new_subclass_edited_id = ''"
                        v-if="new_subclass_edited_id == c._id"
                    />
                </el-col>
            </el-row>
        </el-card>
        
    </div>
    <el-button type="primary" icon="el-icon-plus" @click="addClass">Dodaj kolejną</el-button>
    </div>
    
    <el-button 
        type="success" 
        :loading="send_data_loading"
        icon="el-icon-check"
        class="mt-2"
        @click="saveData"
    >
    
        Pobierz i zapisz wniosek
    </el-button>
</div>
</template>

<script>
export default {
    data () {
        return {
            register_image_checkbox: false,
            register_text_checkbox: false,
            register_text: "",
            register_image: "",
            new_subclass_input: "",
            new_subclass_edited_id: "",
            register_images: [],
            classes: [],
            add_class_loading: false,
            regions: [],
            send_data_loading: false,
            file_list_size: 0,
            client_data: {
                name: "",
                country: "",
                street: "",
                tax_number: "",
                city: "",
                email: "",
                zip_code: "",
                phone: "",
            },
            draft_id: ""
            
        };
    },
    computed:{
        is_fetched(){
            return(this.$route.query.id && this.$route.query.id.length > 1)
        }
    },
    methods: {
        changeUpload(f, file_list){
            
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        addClass(){
            if(this.add_class_loading) return;
            this.add_class_loading = true;
            this.classes.push({
                number: 1,
                subclasses: [],
                _id: Math.random()+Math.random()
            })
            this.add_class_loading = false;
        },
        handleChangeClass(value, id) {
            const ix = this.classes.findIndex((_) => {
                return (_.number == value && id != _._id);
            })
            if(ix != -1){
                this.$message({
                    message:'Uwaga, istnieje już wiadomość o takim numerze',
                    type: 'warning'
                });
            }
        },
        addSubclass(id){
            if(this.new_subclass_input == "") return;
            const ix = this.classes.findIndex((_) => {
                return (id == _._id);
            })
            if(ix == -1){
                return this.$message({
                    message:'Wystąpił błąd (kod błędu: 203x)',
                    type: 'error'
                });
            }
            const ix2 = this.classes[ix].subclasses.findIndex((_) => {
                return (_ == this.new_subclass_input);
            })
            if(ix2 != -1){
                return this.$message({
                    message:'Dodano już dokładnie taką subklasę',
                    type: 'error'
                });
            }
            this.classes[ix].subclasses.push(this.new_subclass_input);
            this.new_subclass_input = "";
        },
        deleteSubclass(to_delete, parent_id){
            const ix = this.classes.findIndex((_) => {
                return (parent_id == _._id);
            })
            if(ix == -1){
                return this.$message({
                    message:'Wystąpił błąd (kod błędu: 303x)',
                    type: 'error'
                });
            }
            const ix2 = this.classes[ix].subclasses.findIndex((_) => {
                return (_ == to_delete);
            })
            if(ix2 == -1){
                return this.$message({
                    message:'Wystąpił błąd (kod błędu: 304x)',
                    type: 'error'
                });
            }
            this.classes[ix].subclasses.splice(ix2, 1)
        },
        deleteClass(id){
            const ix = this.classes.findIndex((_) => {
                return (id == _._id);
            })
            if(ix == -1){
                return this.$message({
                    message:'Wystąpił błąd (kod błędu: 403x)',
                    type: 'error'
                });
            }
            
            this.classes.splice(ix, 1)
        },
        async saveData(){
            if(this.send_data_loading) return;
            this.send_data_loading = true;
            try{
                const obj = {
                    client_data: this.client_data,
                    regions: this.regions,
                }
                if(this.register_text_checkbox){
                    obj.register_text = this.register_text
                    obj.is_text = true
                }
                if(this.is_fetched){
                    obj.is_fetched = true;
                    obj.draft_id = this.$route.query.id
                }
                if(this.register_image_checkbox == false){
                    obj.remove_image = true
                }
                obj.classes = this.classes.map((_) => {
                    return {
                        number: _.number,
                        subclasses: _.subclasses,
                    }
                })
                const r = await this.$axios.$post("/drafts", obj)
                this.draft_id = r.draft._id;
                if(this.register_image_checkbox == true){
                    if(this.file_list_size == 1){
                        this.$refs.upload.submit();
                    }else{
                        this.refresh();
                    }
                    
                }else{
                    this.refresh();
                }

                
            }catch(e){
                console.error(e)
            }
            
        },
        async refresh(){
            await this.downloadPdf();
            if(this.$refs.upload != undefined) this.$refs.upload.clearFiles()
            if(this.$route.query.id != this.draft_id){
                this.$router.push({path: "/", query: {id: this.draft_id}})
            }
            this.fetchData();
        },
        async downloadPdf(){
            try{
                const r = await this.$axios.$get(`/drafts/${this.draft_id}/download`, 
                {
                    responseType: 'blob'
                });
                // const pdf = r;
                // const newBlob = new Blob([pdf], {type: "application/pdf"})
                // const data = window.URL.createObjectURL(newBlob);
                // let link = document.createElement('a');
                // link.href = data;
                // link.download= this.draft_id+".pdf";
                // link.click();
                // setTimeout(function(){
                //     // For Firefox it is necessary to delay revoking the ObjectURL
                //     window.URL.revokeObjectURL(data);
                // }, 100);
                // console.log(r)

                const URL = window.URL.createObjectURL(r);
                const a = document.createElement('a');
                a.setAttribute('href', URL);
                a.setAttribute('download', `${this.draft_id}.pdf`);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                a.remove();
                setTimeout(_ => {
                    window.URL.revokeObjectURL(URL);
                }, 100);
            }catch(e){
                console.log(e)
            }
        },
        async uploadFile(param){
            try{
                const fileObj = param.file;
                const fd = new FormData()
                fd.append("image", fileObj);
                const r = await this.$axios.$post(`/drafts/${this.draft_id}/image`, fd)
            }catch(e){
                console.log(e)
                this.$message.error('Wystąpił błąd podczas przesyłania pliku[2x21XX]');
            }
        },
        async fetchData(){
            try{
                const r = await this.$axios.$get(`/drafts/${this.draft_id}`)
                this.draft_id = r.draft._id;
                this.fillData(r.draft);
            }catch(e){
                console.log(e)
                this.$message.error('Wystąpił błąd podczas pobierania danych [2021XX]');
            }
            this.send_data_loading = false;
        },
        fillData(d){
            
            this.client_data = d.client_data;
            this.regions = d.regions;
            this.classes = d.classes;
            if(d.register_text.selected){
                this.register_text_checkbox = true;
                this.register_text = d.register_text.text;
            }
            if(d.register_image.selected){
                this.register_image_checkbox = true;
                this.register_image = d.register_image.url;
            }
        }
    },
    mounted(){
        if(this.$route.query.id){
            this.draft_id = this.$route.query.id;
            this.fetchData();
        }
    }
};
</script>
