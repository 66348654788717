<template>
    <div>
        <el-table
            :data="drafts"
            border
            style="width: 100%"
            stripe
            empty-text="Brak danych"
        >
            <el-table-column
                prop=""
                label="Data"
                width="180"
                align="center"
            >
                <template slot-scope="scope" v-if="scope.row">
                    {{ formatDateTime(scope.row.c_date) }}
                </template>
            </el-table-column>
            <el-table-column
                prop=""
                label="Klient"
            >
                <template slot-scope="scope" v-if="scope.row">
                    {{ scope.row.client_data.name }}
                </template>
            </el-table-column>
            <el-table-column width="40" align="center">
                <template slot-scope="scope" v-if="scope.row.sended">
                    <i class="el-icon-message"></i>
                </template>
            </el-table-column>
            <el-table-column
                prop=""
                label="Akcje"
                width="180"
                align="center"
            >
                <template slot-scope="scope" v-if="scope.row">
                    <el-button 
                    type="warning" 
                    icon="el-icon-position" 
                    circle
                    :loading="send_loading"
                    @click="markAsSended(scope.row._id)"
                    ></el-button>
                    
                    <el-button 
                        type="primary" 
                        icon="el-icon-search" 
                        circle 
                        @click="$router.push({ path: '/', query: { id: scope.row._id } })"
                    />
                    <div style="display: inline; margin-left: 10px;">
                        <el-popconfirm
                            title="Czy napewno chcesz usunąć zlecenie?"
                            confirm-button-text= "Usuń"
                            cancel-button-text = "Nie"
                            confirm-button-type	 = "danger"
                            icon="el-icon-delete"
                            icon-color	="red"
                            @confirm="deleteDraft(scope.row._id)"
                        >
                            <el-button :loading="delete_loading" slot="reference" type="danger" icon="el-icon-delete" circle></el-button>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            class="mt-2"
            background
            layout="prev, pager, next"
            :page-count="number_of_pages"
            :current-page="page"
            :page-size="ipp"
            @current-change="paginate"
            
        />
    </div>
</template>
<script>
import {formatDateTime} from '../helpers/format-date-time'
export default {
    data(){
        return{
            drafts: [{c_date: 1623221536875, client_data: {name: "Adrian"} }],
            page: 1,
            number_of_pages: 1,
            ipp: 32,
            formatDateTime,
            send_loading: false,
            delete_loading: false,
        }
    },
    methods: {
        paginate(p){
            this.page = p;
            this.fetchData()
        },
        async fetchData(){
            try{
                const r = await this.$axios.$get(`/drafts/?page=${this.page}`)
                this.drafts = r.drafts;
                this.page = r.pagination.current_page;
                this.ipp = r.pagination.items_per_page;
                this.number_of_pages = r.pagination.number_of_pages;
            }catch(e){
                this.$message.error('Wystąpił błąd podczas pobierania danych [201XX]');
            }
        },
        async deleteDraft(id){
            this.delete_loading = true;
            try{
                const r = await this.$axios.$delete(`/drafts/${id}`)
                this.fetchData()
            }catch(e){
                console.log(e)
                this.$message.error('Wystąpił błąd podczas usuwania [51XX]');
            }
            this.delete_loading = false;
        },
        async markAsSended(id){
            this.send_loading = true;
            try{
                const r = await this.$axios.$put(`/drafts/${id}/mark-as-sended/`)
                const ix = this.drafts.findIndex(_ => {return _._id.toString() == id.toString()})
                if(ix != -1) this.drafts[ix].sended = r.sended
            }catch(e){
                console.log(e)
                this.$message.error('Wystąpił błąd podczas oznaczania [516X]');
            }
            this.send_loading = false;
        }
    },
    mounted(){
        this.fetchData();
    }
}
</script>