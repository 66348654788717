import Vue from 'vue'
import Vuex from 'vuex'
import { Auth } from './modules/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      username: '',
      role: ''
    },
    authData: {
      token: '',
      expDate: ''
    },
  },
  mutations: {
    loginFromStorage: state => {
      if (localStorage.getItem('v_token') && localStorage.getItem('v_expDate')) {
        let d = new Date(localStorage.getItem('v_expDate'));

        if (d.getTime() > Date.now()) {

          state.authData.token = localStorage.getItem('v_token');
          state.authData.expDate = localStorage.getItem('v_expDate');
          state.user.username = localStorage.getItem('v_username');
          state.user.role = localStorage.getItem('v_role');
          Axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('v_token');

        } else {
          localStorage.removeItem('v_token');
          localStorage.removeItem('v_expDate');
          localStorage.removeItem('v_username');
          localStorage.removeItem('v_role');
        }
      }
    },
    logout: state => {
      state.user.username = '';
      state.user.role = '';
      state.authData.token = '';
      state.authData.expDate = '';
      localStorage.removeItem('v_username');
      localStorage.removeItem('v_role');
      localStorage.removeItem('v_token');
      localStorage.removeItem('v_expDate');
    }
  },
  actions: {
  },
  modules: {
    auth: Auth
  }
})
