import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import History from '../views/History.vue'
import Login from '../views/Login.vue'
import Store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'New',
    component: Dashboard
  },
  {
    path: '/historia',
    name: 'History',
    component: History
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {

  let loginState = Store.getters['auth/isLoggedIn'];
  if(!loginState) await Store.dispatch('auth/loginFromStorage');
  loginState = Store.getters['auth/isLoggedIn'];


  if (to.path == "/login" && loginState == true) next('/');
  else if(loginState == false && to.path != "/login") next('/login');
  else next();


});

export default router
