import axios from 'axios';
import { Axios as AxiosConfig } from './config';
axios.defaults.baseURL = AxiosConfig.baseUrl;

function callAxios(config){
    return new Promise(async (res, rej) => {
        try{
            res((await axios(config)).data);
        }
        catch(err){
            rej(err);
        }
    });
}
export const WKAxios = {
    install(Vue, options) {
        Vue.prototype.$axios = {
            $get(url, config){
                return callAxios({
                    url,
                    ...config,
                    method: 'get'
                });
            },
            $post(url, data, config){
                return callAxios({
                    method: 'post',
                    url,
                    data,
                    ...config
                })
            },
            $put(url, data, config){
                return callAxios({
                    method: 'put',
                    url,
                    data,
                    ...config
                })
            },
            $patch(url, data, config){
                return callAxios({
                    method: 'patch',
                    url,
                    data,
                    ...config
                })
            },
            $delete(url, data, config){
                return callAxios({
                    method: 'delete',
                    url,
                    data,
                    ...config
                })
            }
        }
    }
}