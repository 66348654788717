<template>
    <div id="app">
        <div class="layout__page">
            <div class="layout__container">
                <div class="layout__menu" v-if="is_logged_in">
                    <el-menu
                    default-active="1">
                        <el-menu-item index="1" @click="$router.push('/')">
                            <i class="el-icon-document"></i>
                            <span>Nowy</span>
                        </el-menu-item>
                        <el-menu-item index="2" @click="$router.push('/historia')">
                            <i class="el-icon-document-copy"></i>
                            <span>Lista</span>
                        </el-menu-item>
                        <el-menu-item index="3" @click="logout">
                            <i class="el-icon-caret-left"></i>
                            <span>Wyloguj</span>
                        </el-menu-item>
                    </el-menu>
                </div>
                <div class="layout__body">
                    <div class="layout__header">
                        <img src="@/assets/imgs/logo.png">
                    </div>
                    <el-divider></el-divider>
                    <div class="layout__content">
                        <router-view :key="$route.fullPath"/>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<style lang="scss">
    @import "@/assets/scss/index.scss";
</style>
<script>
export default {
    computed:{
        is_logged_in(){
            return this.$store.getters['auth/isLoggedIn'];
        }
    },
    methods: {
        async logout(){
            this.$store.dispatch('auth/logout')
            this.$router.push("/login");
        }
    }
}
</script>